import React, { useState, useEffect, useContext } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from '@mui/icons-material/Done';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import FormEditor from './FormEditor';

const FormUpdate = () => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { formId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [form, setForm] = useState();
  const isOnFreePlan = (!account.plan || !account.plan.code || account.plan.code.startsWith('free'));
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().fetchForm({accPath: `Accounts/${account.id}`, formId: formId})
        .subscribe({
            next: (form) => {
              setLoading(false);
              console.log("FormEdit loaded ", form)
              console.log("FormEdit loaded ", JSON.stringify(form))
              setForm(form);
            },
            error: (err) => setError('There was an error loading the form.')
        });

    return () => {
      subscription.unsubscribe();
    };
  }, [account, formId]);

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      {loading && <LinearProgress color="secondary" />}
      {!isOnFreePlan && <MetricsBox accountId={account.id} formId={formId} />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      {!loading && !form && <Box sx={{padding:'20px', textAlign:'center'}}><i>Form not found</i></Box>}
      {form && <FormEditor formToEdit={form} />}
    </Box>
  )
};

export default FormUpdate;

const MetricsBox = ({accountId, formId}) => {
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [errorMetrics, setErrorMetrics] = useState();
  const [averageResponse, setAverageResponse] = useState();
  
  useEffect(() => {
    setLoadingMetrics(true);
    setErrorMetrics(null);
    Data.getInstance().getAverageFormResponseTime(accountId, formId)
    .then((avg) => {
      setLoadingMetrics(false);
      console.log("Metrics loaded ", avg)
      setAverageResponse(avg);
    })
    .catch((err) => {
      setLoadingMetrics(false);
      setErrorMetrics('There was an error loading the metrics.');
      console.error("MetricsError", err);
    })
  }, [accountId, formId]);

  return <Box sx={{width: '90%', maxWidth: '1160px', px:'24px'}}><Box sx={{p:'20px', mt:'24px', background: '#cfdbd5', borderRadius:'12px'}}>
          <Typography variant='h5' sx={{mb:'16px'}}>Metrics</Typography>
          {loadingMetrics && <CircularProgress size={16} color="secondary" />}
          {errorMetrics && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{errorMetrics}</Box>}
          {!loadingMetrics && !averageResponse && <Typography variant='subtitle2' sx={{color:'#333'}}>No metrics available yet</Typography>}
          {averageResponse && <Box sx={{display:'flex', alignItems:'baseline'}}>
            <Typography variant='subtitle1' sx={{mr:'16px'}}>Average time until response:</Typography>
            <Typography variant='subtitle2' sx={{fontFamily:'monospace'}}><DurationDisplay milliseconds={averageResponse} /></Typography>
          </Box>}
  </Box></Box>
}

const DurationDisplay = ({ milliseconds }) => {
  const calculateDuration = (ms) => {
    // Calculate different time units
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    // Build duration string
    const parts = [];
    
    if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
    if (hours > 0) parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
    if (minutes > 0) parts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds} ${seconds === 1 ? 'second' : 'seconds'}`);

    return parts.join(' ');
  };

  return (
    <span className="font-mono">
      {calculateDuration(milliseconds)}
    </span>
  );
};

// Add prop type validation
DurationDisplay.defaultProps = {
  milliseconds: 0
};