const HR_JOB_APPLICATION_FORM = Object.freeze(
{ name: "HR Review Job Application", 
  form: {
    webhook: "",
    fields: {
      motivationHighlights: {
        uiType: "bulletedList"
      },
      companies: {
        uiType: "cardGrid"
      },
      positionAppliedFor: {
        uiType: "header"
      },
      "9MR5XyhY2R7C2maGqRM2": {
        uiType: "staticText",
        value: "Previous companies"
      },
      assessment: {
        uiType: "rating",
        settings: {
          editable: false,
          max: 10
        }
      },
      decisionSelect: {
        uiType: "buttonSelect",
        settings: {
          multiSelect: false
        },
        required: true,
        values: [
          {
            label: "Send invitation",
            id: "invite",
            style: "accept"
          },
          {
            id: "reject",
            label: "Send rejection",
            style: "reject"
          }
        ]
      },
      cvLink: {
        uiType: "fileLink"
      }
    },
    fieldOrder: [
      "positionAppliedFor",
      "cvLink",
      "9MR5XyhY2R7C2maGqRM2",
      "companies",
      "motivationHighlights",
      "assessment",
      "decisionSelect"
    ],
    name: "Review Job Application",
    dummyRequest: {
      fields: {
        positionAppliedFor: "Head of Global Governance & Policies",
        cvLink: {
            url: "https://www.sbs.ox.ac.uk/sites/default/files/2019-01/cv-template.pdf",
            label: "Open CV"
        },
        assessment: 7.5,
        companies: [
          {
            subtitle: "Head of Governance",
            header: "Reg & Co",
            stats: [
              { key: "sector", value: "Medical" },
              { key: "headcount", value: "760" },
              { key: "revenue", value: "14M" }
            ]
          },
          {
            header: "Tree Publishing",
            subtitle: "Audit Lead",
            stats: [
              { key: "sector", value: "Media" },
              { key: "headcount", value: "1800" },
              { key: "revenue", value: "36M" }
            ]
          },
          {
            header: "AgriTop",
            subtitle: "Head of Controlling",
            stats: [
              { key: "sector", value: "Agriculture" },
              { key: "headcount", value: "42" },
              { key: "revenue", value: "180K" }
            ]
          }
        ],
        motivationHighlights: [
            "Accomplished professional with extensive experience in governance, policy development, and auditing within the wine and spirits industry.",
            "Demonstrated success in enhancing operational efficiencies, achieving over 20% growth in compliance and regulatory adherence across numerous client accounts.",
            "Expertise in developing customized policy frameworks that streamline supplier relationships.",
            "Skilled mentor and trainer, adept at guiding junior staff and interns, contributing to organizational growth and team alignment strategies."
        ]
      },
    }
  }
}
);

const HR_TRAINING_REQUEST = {
  name: "HR Review Training Request", 
  form: {
    webhook:"",
    fields:{"topics":{"uiType":"bulletedList"},"facts":{"uiType":"cardGrid"},"name":{"uiType":"header"},"9MR5XyhY2R7C2maGqRM2":{"uiType":"staticText","value":"Training topics"},"decisionSelect":{"uiType":"buttonSelect","settings":{"multiSelect":false},"required":true,"values":[{"label":"Approve Training","id":"approve","style":"accept"},{"id":"reject","label":"Decline request","style":"reject"}]}},
    fieldOrder:["name","facts","9MR5XyhY2R7C2maGqRM2","topics","decisionSelect"],
    name:"Employee Training Request",
    dummyRequest: {
      fields: {
          name: "Liam Peroni",
          facts: [
            {
              "header": "Spanish Course",
              "subtitle": "Advanced Business Spanish",
              "stats": [
                { "key": "location", "value": "Sevilla" },
                { "key": "costs", "value": "1200 €" }
              ]
            }
          ],
          topics: [
              "Gain fluency in business-specific Spanish terminology to confidently navigate international negotiations, presentations, and correspondence",
              "Enhance your cross-cultural communication skills to build stronger business relationships and effectively collaborate with Spanish-speaking partners and clients",
              "Master practical language applications through immersive role-playing scenarios, case studies, and industry-relevant assignments tailored to your professional needs"
          ]
      },
    }
  }
}

const MARKETING_CONTENT = {
  name: "Marketing Content Approval",
  form: {
    "name":"Review social media posts","fieldOrder":["1728507112784","sourceUrl","summary","1728507728416","twitter","twitterDecision","linkedin","linkedinDecision","blog","blogDecision"],"fields":{"1728507112784":{"uiType":"staticHeader","value":"Source content"},"sourceUrl":{"uiType":"urlLink"},"summary":{"uiType":"text","label":"LLM summary","settings":{"editable":false}},"twitter":{"uiType":"text","label":"X/Twitter post","settings":{"editable":true}},"linkedin":{"uiType":"text","label":"LinkedIn post","settings":{"editable":true}},"1728507728416":{"uiType":"staticHeader","value":"AI content drafts"},"blog":{"uiType":"markdown","label":"Company blog post (Markdown)","settings":{"editable":true}},"twitterDecision":{"uiType":"buttonSelect","required":true,"settings":{"multiSelect":false},"values":[{"id":"publish","label":"Publish","style":"accept"},{"id":"reject","label":"Reject","style":"reject"}]},"linkedinDecision":{"uiType":"buttonSelect","required":true,"settings":{"multiSelect":false},"values":[{"id":"publish","label":"Publish","style":"accept"},{"id":"reject","label":"Reject","style":"reject"}]},"blogDecision":{"uiType":"buttonSelect","required":true,"settings":{"multiSelect":false},"values":[{"id":"publish","label":"Publish","style":"accept"},{"id":"reject","label":"Reject","style":"reject"}]}},
    dummyRequest: {
      "fields": {
          "sourceUrl": {
              "url": "https://vercel.com/blog/vercel-ai-sdk-3-3",
              "label": "Scraped: Vercel AI SDK 3.3"
          },
          "summary": "The Vercel AI SDK 3.3 introduces new features for building AI applications with JavaScript and TypeScript. Major updates include experimental features such as tracing with OpenTelemetry, multi-modal file attachments in chat applications, and the useObject hook for streaming structured object generation. The SDK now supports JSON schemas, stop sequences, and custom headers to provide more flexibility. Additionally, new model providers like AWS Bedrock and Chrome AI have been added. The update emphasizes enhanced observability, structured data handling, and increased flexibility for developers using language models.",
          "twitter": "Checking out Vercel AI SDK 3.3—some useful updates like tracing with OpenTelemetry, multi-modal file attachments in chats, and structured data streaming with useObject. Also, new support for JSON schemas and custom headers. #Vercel #AISDK #LLMs",
          "linkedin": "Just explored the new Vercel AI SDK 3.3. It comes with some useful experimental features like tracing with OpenTelemetry and the ability to handle multi-modal file attachments in chat applications. I also found the useObject hook for streaming structured data to be quite practical. The addition of JSON schema support and new model providers like AWS Bedrock gives more options for developing AI applications. If you're working with JavaScript or TypeScript in AI, it might be worth checking out.",
          "blog": "# AI SDK 3.3 is out!\nVercel has rolled out __AI SDK 3.3__, bringing some promising enhancements for those building AI applications with *JavaScript* and *TypeScript*. This latest release includes experimental features like tracing with OpenTelemetry, which aids in better observability, and multi-modal file attachments, perfect for enriching chat applications.\n\nMoreover, the useObject hook now allows for streaming structured data directly to clients, opening up new possibilities for dynamic interfaces. With the addition of JSON schema support, stop sequences, and custom headers, developers have more control and flexibility in their AI projects.\n\nVercel also expanded its model providers to include AWS Bedrock and Chrome AI. These updates reflect a thoughtful evolution in AI tooling, making it easier and more efficient to create robust AI solutions. It's a great time to explore these tools and see how they can enhance your AI projects!"
      }
    }
  },
}

const INVOICE_REVIEW = {
  name: "Review Payment",
  form: {
    "name":"Approve invoice payment","webhook":"","fieldOrder":["1728511166917","invoicePdf","invoiceAsImage","1728511222148","fields","1728565162132","bankAccount","bankBranch","invoiceNo","amount","paymentDecision"],"fields":{"1728511166917":{"uiType":"staticHeader","value":"Invoice"},"invoicePdf":{"uiType":"fileLink"},"invoiceAsImage":{"uiType":"imageGrid"},"1728511222148":{"uiType":"staticHeader","value":"Detected fields"},"fields":{"uiType":"cardGrid"},"paymentDecision":{"uiType":"buttonSelect","required":true,"settings":{"multiSelect":false},"values":[{"id":"pay","label":"Pay now","style":"accept"},{"id":"pay14days","label":"Pay in 14 days","style":"accept"},{"id":"decline","label":"Decline","style":"reject"}]},"1728565162132":{"uiType":"staticHeader","value":"Review transaction"},"bankAccount":{"uiType":"text","label":"Bank account","settings":{"editable":true}},"bankBranch":{"uiType":"text","label":"Bank branch","settings":{"editable":true}},"invoiceNo":{"uiType":"text","label":"Invoice No","settings":{"editable":true}},"amount":{"uiType":"text","label":"Amount $","settings":{"editable":true}}},
    dummyRequest: {
      "fields": {
        "invoicePdf": {
            "url": "https://slicedinvoices.com/pdf/wordpress-pdf-invoice-plugin-sample.pdf",
            "label": "Open invoice"
        },
        "invoiceAsImage": [
            {
                "label": "Invoice snapshot",
                "url": "https://cdn.prod.website-files.com/6605a2979ff17b2cd1939cd4/670705d0adb2149694b30293_invoice-sample.JPG"
            }
        ],
        "fields": [
            {
                "header": "Issuer",
                "subtitle": "Sliced Invoices",
                "stats": [
                    {
                        "key": "street",
                        "value": "123 Somewhere Street"
                    },
                    {
                        "key": "city",
                        "value": "Your City AZ 12345"
                    }
                ]
            },
            {
                "header": "Bank account",
                "subtitle": "ANZ Bank",
                "stats": [
                    {
                        "key": "ACC",
                        "value": "1234 1234"
                    },
                    {
                        "key": "BSB",
                        "value": "4321 432"
                    }
                ]
            },
            {
                "header": "Invoice fields",
                "subtitle": "93.50",
                "stats": [
                    {
                        "key": "invoiceNo",
                        "value": "INV-3337"
                    },
                    {
                        "key": "orderNo",
                        "value": "12345"
                    },
                    {
                        "key": "invoiceDate",
                        "value": "25-01-2016"
                    },
                    {
                        "key": "dueDate",
                        "value": "31-01-2016"
                    }
                ]
            }
        ],
        "bankAccount": "1234 1234",
        "bankBranch": "4321 432",
        "invoiceNo": "INV-3337",
        "amount": "93.50"
    }
    }
  }
}

const SALES_LEAD = {
  name: "Sales Lead Outreach",
  form: {
    "name":"Sales lead outreach","fieldOrder":["1728554176284","email","1728554601198","linkedin","companies","1728555949336","posts","emailDraft","ignoreOption"],"fields":{"1728554176284":{"uiType":"staticHeader","value":"New lead"},"email":{"uiType":"text","label":"Email address","settings":{"editable":false}},"1728554601198":{"uiType":"staticText","value":"Potential LinkedIn match"},"linkedin":{"uiType":"urlLink"},"companies":{"uiType":"cardGrid"},"1728555949336":{"uiType":"staticText","value":"Last posts"},"posts":{"uiType":"bulletedList"},"emailDraft":{"uiType":"text","label":"Email draft by AI","settings":{"editable":true}},"ignoreOption":{"uiType":"buttonSelect","required":false,"settings":{"multiSelect":false},"values":[{"id":"ignore","label":"Ignore","style":"reject"}]}},
    dummyRequest: {
      "fields": {
        "email": "malte@deepset.ai",
        "linkedin": {
            "url": "https://www.linkedin.com/in/maltepietsch/",
            "label": "Malte Pietsch"
        },
        "companies": [
            {
                "header": "deepset",
                "subtitle": "Co-Founder",
                "stats": [
                    {
                        "key": "Since",
                        "value": "Apr 2018"
                    },
                    {
                        "key": "Employees",
                        "value": "10-50"
                    },
                    {
                        "key": "Funding",
                        "value": "Series B - $45M"
                    },
                    {
                        "key": "HQ",
                        "value": "Berlin, Germany"
                    }
                ]
            },
            {
                "header": "plista",
                "subtitle": "Data Scientist",
                "stats": [
                    {
                        "key": "Since",
                        "value": "Apr 2016"
                    },
                    {
                        "key": "HQ",
                        "value": "Berlin"
                    }
                ]
            }
        ],
        "posts": [
            "A new \"Building AI Applications with Haystack\" course by DeepLearning.AI, featuring Tuana Çelik and Andrew Ng, teaches developers to create adaptable AI applications, even without prior Haystack knowledge.",
            "Deepset has launched \"deepset Studio,\" a tool that enhances developers' AI pipeline building with a visual editor, facilitating seamless integration between local IDEs and the studio, and is inviting user feedback to shape future features.",
            "The post highlights two key challenges AI teams face: selecting the right use cases and maintaining iteration speed, emphasizing the importance of balancing out-of-the-box solutions with customization to drive business value."
        ],
        "emailDraft": "Hi Malte,\nI saw the news about deepset Studio and your collaboration with DeepLearning.AI on the Haystack course. These are noteworthy strides in AI application development.\n\nGiven your momentum in the AI space, influencer marketing could be a powerful tool to further engage and expand your developer community. At Influx, we specialize in helping scale-ups like deepset by connecting them with influencers who can effectively amplify their message.\n\nI\’d love to explore how we can assist in elevating deepset\'s visibility and impact. Could we schedule a quick call next week?\nBest regards,\nBen\nInflux Marketing Agency\nben@influx.com"
    }
    }
  }
}

const SALES_LEAD_SIMPLE = {
  name: "Sales Lead (Simple)",
  form: {
    name:"Sales lead outreach (simple)",
    fields:{"emailDomain":{"uiType":"urlLink"},"email":{"label":"Email address","settings":{"editable":false},"uiType":"text"},"emailApproval":{"uiType":"buttonSelect","values":[{"style":"accept","id":"approve","label":"Send email"},{"style":"approve","label":"Dismiss","id":"reject"}],"settings":{"multiSelect":false},"required":true},"emailDraft":{"label":"Email draft by AI","settings":{"editable":true},"uiType":"text"},"websiteSummary":{"uiType":"text","label":"Website Summary","settings":{"editable":false}},"1728554176284":{"uiType":"staticHeader","value":"New lead"}},
    fieldOrder:["1728554176284","email","emailDomain","websiteSummary","emailDraft","emailApproval"],
    webhook:"",
    dummyRequest: {
      fields: {
        "emailDomain":{"url":"https://openai.com","label":"Website"},
        "email":"lenny@openai.com",
        "emailDraft":"Hi Lenny,\nI saw the news about your latest model.\n\nGiven your momentum in the AI space, influencer marketing could be a powerful tool to further engage and expand your developer community. At Influx, we specialize in helping tech companies like OpenAI by connecting them with influencers who can effectively amplify their message.\n\nI’d love to explore how we can assist in elevating OpenAI's visibility and impact. Could we schedule a quick call next week?\nBest regards,\nBen\nInflux Marketing Agency\nben@influx.com",
        "websiteSummary":"OpenAI is a leading AI research lab with a unique structure that includes the for-profit OpenAI LP and the non-profit OpenAI Inc. Its mission is to ensure that artificial general intelligence (AGI) benefits humanity, emphasizing ethical and safe AI development. OpenAI is known for its advanced language models, like GPT-3, which can understand and generate human-like text for various applications, from chatbots to creative writing. The organization prioritizes safety and ethical considerations in its research, aiming to create powerful AI systems aligned with human values. This dual structure allows OpenAI to balance commercial interests with its public benefit mission, positioning it as a key player in responsibly advancing AI technology for the greater good."
      },
      meta:{"threadId":"17861389273"}
    }
  }
}
export const TEMPLATES = [MARKETING_CONTENT, SALES_LEAD, SALES_LEAD_SIMPLE, HR_JOB_APPLICATION_FORM, HR_TRAINING_REQUEST, INVOICE_REVIEW]