import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import AppRoot from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import * as Sentry from "@sentry/react";
import {amber, lightBlue, lightGreen} from '@mui/material/colors';
import FirebaseManager from './FirebaseManager'; //KEEP! This import inits Firebase!

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor, name) => augmentColor({ color: { main: mainColor }, name: name });
let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
  palette: {
    primary: {
      main: '#3B7080'
    },
    secondary: {
      main: '#3B7080'
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter,Segoe UI,Roboto,Helvetica Neue,sans-serif',
      color: '#1e1e1e'
    },
    button: {
      textTransform: 'none',
    }
  },
});
theme = responsiveFontSizes(theme)
theme = createTheme(theme, {
  palette: {
    whitey: createColor('#FFF', 'whitey'),
    approve: createColor('#6DA34D', 'approve'),
    accept: createColor('#6DA34D', 'accept'),
    reject: createColor('#AF3800', 'reject'),
    archive: createColor('#e5b603', 'archive'),
    darkPetrol: createColor('#114B5F', 'darkPetrol'),
    rose: createColor('#E26D5C', 'rose'),
    accent: createColor('#F5CB5C', 'accent'),
  },
});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

Sentry.init({
  dsn: "https://1eca5767beadc63463d6625c58903fc8@o4508114947866624.ingest.de.sentry.io/4508114953502800",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppRoot />
      </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
