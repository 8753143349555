import React, { useState, useEffect, useContext } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from '@mui/icons-material/Done';
import Data from './Data';
import StarsIcon from '@mui/icons-material/Stars';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import FormEditor from './FormEditor';

const Checkout = ({}) => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const isOnFreePlan = (!account.plan || !account.plan.code || account.plan.code.startsWith('free'));
  
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: {xs: '0px', sm: '24px'},
      // paddingTop: {xs: '14px', lg: '40px'},
      // paddingBottom: '60px',
      // backgroundImage:"url('/bg_dots.png')"
    }}>
      <Box sx={{
        display:'flex', flexDirection:'column', alignItems:'center', 
        width: '100%',
        height: '100%',
        py: {xs: '80px', sm: '200px'},
        backgroundImage: {xs: "radial-gradient(circle,#cfdbd5 20%,#fff0 70%)", sm: "radial-gradient(circle,#cfdbd5 15%,#fff0 25%)"},
        }}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', minHeight:'232px', background:'#fafafa', borderRadius:'8px', px:'20px', py:'40px', width:'90%', maxWidth: '500px'}}>
          {!isOnFreePlan && <>
            <Typography variant='h4' sx={{fontWeight:'600', marginBottom:'20px', textAlign:'center'}}>Welcome to Pro</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', textDecoration:'none', background:'#E26D5C', borderRadius:'16px', px:'16px', py:'4px', mb:'20px' }}>
              <StarsIcon sx={{fontSize:'18px', color: '#fff'}} />
              <Typography variant="subtitle2" sx={{ ml:'8px', color:'#fff', textDecoration:'none' }}>Upgrade successful</Typography>
            </Box>
            <Typography variant='body2' sx={{textAlign:'center'}}>Build AI workflows with unlimited human reviews, response data sets, and more...</Typography>
          </>}
          {isOnFreePlan && <Box sx={{display:'flex', alignItems:'center', gap:'14px'}}><CircularProgress size='16px' /><Typography variant='body2'>Please wait for subscription to activate</Typography></Box>}
        </Box>
      </Box>
    </Box>
  )
};

export default Checkout;