import React, { useState, useEffect, createContext } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Review from './Review';

const PublicReview = ({user}) => {
  
  return (
    <Box sx={{
      height: '100%',
      minHeight:'100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundImage:"url('/bg_dots.png')",
    }}>
      <Box sx={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        alignContent: 'center',
        pt:'50px',
        pb:'0px',
        backgroundImage: "radial-gradient(circle,#cfdbd5 50%,#fff0 75%)",
        }}>
        <Review user={user} fromPublicLink={true} />
      </Box>
    </Box>
  )
};

export default PublicReview;