const UI_TYPES_SHOW_STATIC_OBJ = Object.freeze({
  STATIC_HEADER: "staticHeader",
  STATIC_TEXT: "staticText",
})
const UI_TYPES_COLLECT_INPUT_OBJ = Object.freeze({
  BUTTON_SELECT: "buttonSelect",
  TEXT_INPUT: "textInput",
  CHECKBOXES: "checkboxes",
  DROPDOWN: "dropdown",
})

const UI_TYPES_SHOW_DATA_OBJ = Object.freeze({
  HEADER: "header",
  TEXT: "text",
  MARKDOWN: "markdown",
  IMAGE_GRID: "imageGrid",
  FILE_LINK: "fileLink",
  URL_LINK: "urlLink",
  CARD_GRID: "cardGrid",
  RATING: "rating",
  BULLETED_LIST: "bulletedList",
})

export const UI_TYPE = Object.freeze({
  ...UI_TYPES_SHOW_STATIC_OBJ,
  ...UI_TYPES_COLLECT_INPUT_OBJ,
  ...UI_TYPES_SHOW_DATA_OBJ,
});

export const UI_TYPES_SHOW_STATIC = Object.values(UI_TYPES_SHOW_STATIC_OBJ)
export const UI_TYPES_COLLECT_INPUT = Object.values(UI_TYPES_COLLECT_INPUT_OBJ)
export const UI_TYPES_SHOW_DATA = Object.values(UI_TYPES_SHOW_DATA_OBJ)