import {Observable, of, forkJoin, combineLatest, from, throwError, merge} from 'rxjs';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  filter,
  flatMap,
  first,
  concatAll,
  startWith,
  pairwise,
  delay,
  mergeMap,
} from 'rxjs/operators';
import {getAuth, sendEmailVerification, deleteUser} from 'firebase/auth';
import {getFirestore, connectFirestoreEmulator, doc, collection, addDoc, onSnapshot, updateDoc, deleteDoc, query, where, documentId, orderBy, limit, startAt, serverTimestamp, arrayUnion, getDoc, getDocs, Timestamp, and, or, getAggregateFromServer, average, getCountFromServer} from 'firebase/firestore';
import {getStorage, ref} from 'firebase/storage';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getApp } from "firebase/app";
import FirebaseManager from './FirebaseManager';
import {collectionData, docData, collectionChanges, doc as fetchDoc} from 'rxfire/firestore';
import {authState} from 'rxfire/auth';
import {getDownloadURL} from 'rxfire/storage';
import update from 'immutability-helper';
import { RUN_STATE, RUN_STATE_GROUP, mapRunStateGroup } from './RunStates';

const Data = (function() {
  const REVIEW_LOAD_LIMIT = 50;
  const LOCAL_STORAGE_FORM_KEY = "pre-sign-up-form"
  function SingletonClass() {
    this.streamLoginState = () => {
      const ctx = this;
      return Observable.create(function(observer) {
        getAuth().onIdTokenChanged(function(user) {
          console.log('onIdTokenChanged ' + JSON.stringify(user));
          observer.next(user);
        });
      })
          .pipe(
              startWith("started"),
              pairwise(),
              switchMap(([prevUser, user]) => {
                console.log(`prev ${prevUser} user ${user}`)
                if (prevUser === null && user) {
                  // console.log(`onIdTokenChanged creat ${user.createdAt} last ${user.lastLoginAt} metaCr ${user.metadata.creationTime} metaLast ${user.metadata.lastSignInTime}`)
                  const newUser = (user.metadata.creationTime || -1) == (user.metadata.lastSignInTime || 0)
                  FirebaseManager.trackEvent(newUser ? "sign_up" : "login", { method: ((user.providerData && user.providerData.length && user.providerData[0].providerId) ? user.providerData[0].providerId : "unknown") })

                  if (!user.emailVerified) { //send on every new login
                    return ctx.sendVerificationEmail(user).pipe(map(x => user), catchError(err => of(user)));
                  }
                }
                return of(user);
              }),
              switchMap((user) => {
                if (user) {
                  return docData(doc(db, 'Users', user.uid), {idField: 'id'})
                      .pipe(
                          map((userData) => {
                            if (userData) {
                              userData.email = user.email; userData.emailVerified = user.emailVerified; userData.name = user.displayName; userData.photoURL = user.photoURL;
                              return userData;
                            } else {
                              user.id = user.uid;
                              user.name = user.displayName;
                              return user;
                            }
                          }),
                          switchMap((userData) => {
                            console.log('new data from user ' + userData.id);
                            let accStreams;
                            if (userData && userData.access) {
                              accStreams = Object.keys(userData.access).map((accPath) => {
                                console.log('Object.keys ' + accPath);
                                return docData(doc(db, accPath), {idField: 'id'})
                                    .pipe(
                                        catchError(() => of({error: true})),
                                        tap((acc) => console.log("got acc " + accPath + JSON.stringify(acc))),
                                        map((acc) => {
                                          acc.path = accPath; return acc;
                                        }),
                                    );
                              });
                            }
                            console.log("streams " + (accStreams ? accStreams.length : 0));
                            if (accStreams && accStreams.length) {
                              return combineLatest(accStreams).pipe(
                                tap((accs) => console.log("got2 acc " + JSON.stringify(accs))),
                                  map((accs) => {
                                    userData.accounts = accs.filter(acc => !acc.error); return userData;
                                  }),
                                  catchError(() => of(userData)),
                              );
                            } else return of(userData);
                          }),
                      );
                } else return of(null);
              }),
              distinctUntilChanged(),
          );
    };

    this.sendVerificationEmail = (user) => {
      return Observable.create(function(observer) {
        console.log('Sending verification email ' + JSON.stringify(user));
        sendEmailVerification(user, {url: 'https://app.gotohuman.com/'}).then(function() {
          console.log('Sent verification email');
          observer.next('');
          observer.complete();
        }).catch(function(error) {
          console.error('Error sending verification email: ', error);
          observer.error(error);
        });
      });
    };

    this.refreshUser = () => {
      const user = getAuth().currentUser;
      console.log('refreshUser ' + JSON.stringify(user));
      if (user) {
        user.reload();
        user.getIdToken(true);
      } else {
        // No user is signed in.
      }
    };

    this.logout = () => {
      getAuth().signOut();
    };

    this.deleteUser = () => {
      return deleteUser(getAuth().currentUser);
    };

    this.createAccountForUser = (accountName) => {
      console.log('createAccountForUser ' + accountName);
      const callable = httpsCallable(functions, 'createaccount');
      return from(callable({accountName: accountName})
        .then((result) => {console.log("createResponse: " + JSON.stringify(result.data));return result.data})
      )
    };

    this.addUserToAccount = (accPath, userId, role) => {
      console.log('addUserToAccount ' + accPath + ' ' + userId);
      return updateDoc(doc(db, accPath + '/Restricted', 'ACL'), {
        [`roles.${userId}`]: role,
      });
    };

    this.changeBrandingSettingForAccount = (accPath, hideBranding) => {
      console.log('changeBrandingSettingForAccount ' + accPath + ' ' + hideBranding);
      return updateDoc(doc(db, accPath), {
        ['settings.hideBranding']: hideBranding,
      });
    };

    this.createCheckoutSession = (accountId) => {
      console.log('createCheckoutSession ' + accountId);
      const callable = httpsCallable(functions, 'createCheckoutSession');
      return callable({accountId: accountId})
        .then((result) => {console.log("createCheckoutSession Response: " + JSON.stringify(result.data));return result.data})
    };

    this.createPortalSession = (accountId) => {
      console.log('createPortalSession ' + accountId);
      const callable = httpsCallable(functions, 'createStripePortalSession');
      return callable({accountId: accountId})
        .then((result) => {console.log("createPortalSession Response: " + JSON.stringify(result.data));return result.data})
    };

    this.getReviewCountInCycle = async (accountId) => {
      console.log('getReviewCountInCycle ' + accountId);
      const callable = httpsCallable(functions, 'getCycleStartDate');
      const result = await callable({accountId: accountId})
      const cycleStartedAt = result.data.cycleStartedAt
      console.log("getCycleStartDate Response: " + JSON.stringify(result.data));
      const collectionRef = collection(db, `Accounts/${accountId}/ReviewRequests`);
      const query2 = query(collectionRef, where("createdAt", ">=", new Date(cycleStartedAt)), orderBy("createdAt", "desc"));
      const snapshot = await getCountFromServer(query2);
      return {
        reviewCount: snapshot.data().count,
        cycleStartedAt: cycleStartedAt,
      };
    };

    this.deleteDoc = (path) => {
      console.log('deleteDoc ' + path);
      const callable = httpsCallable(functions, 'deleteDoc');
      return callable({path: path})
      .then((result) => {console.log("deleteDoc: " + JSON.stringify(result.data));return result.data})
      .catch((error) => {console.error(`deleteDocERROR`, error)})
    }

    this.fetchApiKey = (accPath) => {
      console.log('fetchApiKey ' + accPath);
      return collectionData(query(collection(db, accPath + '/ApiKeys'), limit(1)))
        .pipe(
          catchError(e => {console.error('fetchApiKey err', e); return of([])}),
          map(keys => keys.length ? keys[0].apiKey : ""),
          tap(key => console.log("Got key : " + key))
        );
    };

    this.streamAccount = (accPath) => {
      console.log('streamAccount ' + accPath);
      return fetchDoc(doc(db, accPath))
          .pipe(
              tap((snapshot) => console.log(!snapshot.exists() ? 'noAccDoc' : ('firerx ' + snapshot.id + '  path: ' + accPath + '   DATA: ' + JSON.stringify(snapshot.data())))),
              map((snapshot) => !snapshot.exists() ? undefined : Object.assign({}, snapshot.data(), {'id': snapshot.id, 'path': accPath})),
              catchError(e => {console.error('streamAccount err', e); return of(undefined);}),
          );
    };

    this.streamReviews = ({accPath, formId, user}) => {
      const date14daysAgo = new Date((new Date()).getTime() - (14 * 24 * 60 * 60 * 1000)) // , where('createdAt', '>=', date14daysAgo)
      console.log('streamReviews ' + accPath + ' timestampCutoff ' + date14daysAgo.toLocaleString("de"));
      return combineLatest([
        collectionData(query(collection(db, accPath + '/ReviewRequests'), and(where('state', '==', 'pending'), or(where('userAccess.all', '==', true), where('userAccess.emails', 'array-contains', user.email))), orderBy('createdAt', 'desc'), limit(REVIEW_LOAD_LIMIT)), { idField: 'docId' }),
        collectionData(query(collection(db, accPath + '/Forms')), { idField: 'docId' }).pipe(map(forms => new Map(forms.map(obj => [obj.docId, obj]))))
      ])
        .pipe(          
          tap(([reviews, formsMap]) => console.log("Got reviewsAndForms : " + (reviews.length ? JSON.stringify(reviews): "none") + "  "  + (formsMap ? JSON.stringify(formsMap): "none"))),
          map(([reviews, formsMap]) => reviews.map(review => Object.assign(review, {form: formsMap.get(review.formId)})).filter(review => review.form)), //filter in case form missing
          tap(reviews => console.log("Got reviews : " + (reviews.length ? JSON.stringify(reviews): "none"))),
        );
    };

    this.fetchReview = ({accPath, reviewId, user}) => {
      console.log('fetchReview ' + reviewId);
      return from(getDoc(doc(db, accPath + '/ReviewRequests', reviewId)))
      .pipe(
        // map((snap) => snap.exists() ? (((snap.data().createdAt?.toMillis() || 0) > ((new Date()).getTime() - (2 * 24 * 60 * 60 * 1000))) ? snap.data() : {expired:true}) : undefined),
        map((snap) => snap.exists() ? snap.data() : undefined),
        tap(review => console.log("fetched review", review)),
        switchMap(review => !review ? of(null) : (review.expired ? of (review) : (!review.formId ? of(review) : from(getDoc(doc(db, accPath + '/Forms', review.formId)))
          .pipe(
            switchMap(formSnap => //doing this again to stream the request but not the form (to avoid conflicts with form changes while user is editing)
              docData(doc(db, `${accPath}/ReviewRequests/${reviewId}`), { idField: 'docId' })
              .pipe(
                map(review => (!user || !review.userAccess || review.userAccess.all || (review.userAccess.emails || []).includes(user.email)) ? review : undefined),
                tap(review => console.log("RR review", review)),
                map(review2 => (review2 && formSnap.exists()) ? Object.assign(review2, {form: formSnap.data()}) : review2)
              )
            )
          ))))
      )
    }

    this.deleteReview = ({userId, userEmail, accountId, reviewId}) => {
      console.log('deleteReview ' + accountId + ' ' + reviewId);
      return updateDoc(doc(db, `Accounts/${accountId}/ReviewRequests`, reviewId), {
        state: 'deleted',
        deletedAt: serverTimestamp(),
        deletedByUserId: userId,
        deletedByUserEmail: userEmail,
      })
      .then(() => console.log("deletedReview " + formId))
      .catch((e) => console.error('deletedReview error: ', e));
    }

    this.fetchForm = ({accPath, formId}) => {
      console.log('fetchForm ' + formId);
      return from(getDoc(doc(db, accPath + '/Forms', formId)))
      .pipe(map((snap) => snap.exists() ? {...snap.data(), docId: snap.id} : undefined))
    }

    this.streamForms = ({accPath}) => {
      console.log('streamForms ' + accPath);
      return collectionData(query(collection(db, accPath + '/Forms')), { idField: 'docId' })
        .pipe(
          tap(forms => console.log("Got forms : " + (forms.length ? JSON.stringify(forms): "none"))),
        );
    };

    this.markFormDeleted = ({accPath, formId}) => {
      console.log('markFormDeleted ' + accPath + ' ' + formId);
      return updateDoc(doc(db, `${accPath}/Forms`, formId), {
        deleted: true,
      })
      .then(() => console.log("markEDFormDeleted " + formId))
      .catch((e) => console.error('markFormDeleted error form doc: ', e));
    }
  
    this.sendFormSubmit = (userId, userEmail, accountId, reviewId, fromPublicLink, inputValues, millisSinceRequest) => {
      console.log('sendFormSubmit ' + userId + ' ' + userEmail + ' ' + accountId + ' ' + reviewId + ' ' + millisSinceRequest, inputValues);
      return from(updateDoc(doc(db, `Accounts/${accountId}/ReviewRequests`, reviewId), {
        state: "responded",
        respondedAt: serverTimestamp(),
        respondentAuthed: !!userId,
        ...(userId && {userIdResponded: userId}),
        ...(userEmail && {userEmailResponded: userEmail}),
        respondedInXMillis: millisSinceRequest,
        respondedFromPublicLink: fromPublicLink,
        responseValues: inputValues,
        docV: 1,
      })
      .then(() => console.log("Updated review doc with human response " + reviewId))
      .catch((e) => console.error('sendFormSubmit error updating review doc: ', e)));
    };

    this.getAverageFormResponseTime = (accountId, formId) => {
      const coll = collection(db, `Accounts/${accountId}/ReviewRequests`);
      const q = query(coll, where('formId', '==', formId), orderBy('respondedInXMillis'));
      return getAggregateFromServer(q, {
        averageResponseTime: average('respondedInXMillis')
      }).then((snapshot) => snapshot.data().averageResponseTime);
    }
  
    this.addForm = (accountId, form) => {
      console.log('addForm ' + accountId, form);
      return from(addDoc(collection(db, `Accounts/${accountId}/Forms`), {
        ...form,
        createdAt: serverTimestamp(),
      })
      .then((ref) => {console.log("Added form doc " + ref.id); return ref.id})
      .catch((e) => console.error('addForm error: ', e)));
    };
  
    this.updateForm = (accountId, formId, form) => {
      console.log('updateForm ' + accountId + ' ' + formId, form);
      return from(updateDoc(doc(db, `Accounts/${accountId}/Forms`, formId), {
        ...form,
        modifiedAt: serverTimestamp(),
      })
      .then(() => console.log("Updated form doc" + formId))
      .catch((e) => console.error('updateForm error doc: ', e)));
    };

    this.streamRuns = ({accPath, agentId}) => {
      console.log('streamRuns ' + accPath);
      const queryConstraints = [where('state', 'in', [RUN_STATE.TASK_RUNNING, RUN_STATE.TASK_DONE, RUN_STATE.REQUESTED_HUMAN, RUN_STATE.SERVED_TO_HUMAN, RUN_STATE.APPROVED, RUN_STATE.REJECTED]), orderBy('lastModified', 'desc'), limit(REVIEW_LOAD_LIMIT)]
      if (agentId)
        queryConstraints.push(where('agentId', '==', agentId))
      return combineLatest([
        collectionData(query(collection(db, accPath + '/AgentRuns'), ...queryConstraints), { idField: 'docId' }).pipe(map((runs) => runs.filter((run) => !run.parentRunId))),
        collectionData(query(collection(db, accPath + '/Agents')), { idField: 'docId' }).pipe(map(agents => new Map(agents.map(obj => [obj.id, obj]))))
      ])
        .pipe(
          map(([runs, agentsMap]) => runs.map(run => Object.assign(run, {agent: agentsMap.get(run.agentId), stateGroup: mapRunStateGroup(run.state)})).filter(run => run.agent)), //filter because it could be that there is a run but a new agent doc wasn't created yet
          tap(runs => console.log("Got runs : " + (runs.length ? JSON.stringify(runs): "none"))),
        );
    };

    this.streamRun = ({accountId, runDocId, runId}) => {
      console.log('streamRun ' + accountId + ' ' + runDocId + ' ' + runId);
      const ctx = this;
      return combineLatest([
        (runDocId ? docData(doc(db, `Accounts/${accountId}/AgentRuns/${runDocId}`), { idField: 'docId' }) : collectionData(query(collection(db, `Accounts/${accountId}/AgentRuns`), where('runId', '==', runId), limit(1)), { idField: 'docId' }).pipe(tap(arr=>console.log("gotForRunId: " + arr.length)),map((arr) => arr.length>0 ? arr[0] : undefined)))
          .pipe(
            tap(run => console.log("streamedRun", run)),
            switchMap(run => !run ? of({}) : collectionData(query(collection(db, `Accounts/${accountId}/AgentRuns/${run.docId}/Steps`), orderBy('millisClient', 'asc')), { idField: 'docId' })
              .pipe(
                map(steps => ctx.ensureRunStepOrder(steps)),
                map(steps=> Object.assign(run, {stateGroup: mapRunStateGroup(run.state), steps: steps}))
              ))
          ),
        collectionData(query(collection(db, `Accounts/${accountId}/Agents`)), { idField: 'docId' }).pipe(map(agents => new Map(agents.map(obj => [obj.id, obj]))))
      ])
        .pipe(
          tap(run => console.log("streamedRun2", run)),
          map(([run, agentsMap]) => Object.assign(run, {agent: agentsMap.get(run.agentId)})),
          filter(run => !run.docId || run.agent), //filter because it could be that there is a run but a new agent doc wasn't created yet | if we found a run doc it needs to have an agent
          tap(run => console.log("Got run : " + JSON.stringify(run))),
        );
      };

      this.ensureRunStepOrder = (steps) => {
        const mapResponsesByTaskId = new Map(steps.filter(step => [RUN_STATE.APPROVED, RUN_STATE.REJECTED].includes(step.state)).map(step => [step.taskId, step]))
        const tasksWithoutResponses = steps.filter(step => !([RUN_STATE.APPROVED, RUN_STATE.REJECTED].includes(step.state)))
        const resultArr = [];
        tasksWithoutResponses.forEach(task => {
            resultArr.push(task);
            const response = mapResponsesByTaskId.get(task.taskId);
            if (response) {
                resultArr.push(response);
            }
        });
        console.log(resultArr);
        return resultArr;
      }

      this.streamChildRuns = (accountId, runId) => {
        console.log('streamChildRuns ' + accountId + ' ' + runId);
        return collectionData(query(collection(db, `Accounts/${accountId}/AgentRuns`), where('parentRunId', '==', runId)), { idField: 'docId' })
      };

      this.streamRunTriggeredWebhooks = (accountId, runDocId) => {
        console.log(`streamRunTriggeredWebhooks ${accountId} ${runDocId}`);
        return collectionData(query(collection(db, `Accounts/${accountId}/AgentRuns/${runDocId}/WebhooksTriggered`), orderBy('triggeredAt', 'desc'), limit(10)), { idField: 'docId' })
          .pipe(
            // catchError(e => console.error('streamRunTriggeredWebhooks err', e)),
            tap(hooks => console.log("Got webhooks riggered : " + (hooks.length ? JSON.stringify(hooks): "none"))),
          );
      };
  
    this.sendHumanReaction = (userId, userEmail, accountId, runDocId, taskId, taskName, actionValues, humanResponseState, subState = null) => {
      console.log('sendHumanReaction ' + taskId + ' ' + userId + ' ' + userEmail + ' ' + accountId + ' ' + runDocId + ' ' + humanResponseState);
      const now = Timestamp.now();
      return merge(
        from(addDoc(collection(db, `Accounts/${accountId}/AgentRuns/${runDocId}/Steps`), {
          state: humanResponseState,
          ...(subState && {subState: subState}),
          userIdResponded: userId,
          userEmailResponded: userEmail,
          ...(taskId && {taskId: taskId}),
          ...(taskName && {taskName: taskName}),
          ...(actionValues && {actionValues: actionValues}),
          timestamp: now,
          millisClient: now, //prone to clock skew/manipulations, but I need local (not serverside) time settings or local firestore update misses timestamp until synced after some secs again (and it's used for sorting with steps whoes time is set @agent-API-side anyway.)
          docV: 1,
        })
          .then((docRef) => `Created run step doc ${docRef.path} with human reaction`)
          .catch((e) => console.error('sendHumanReaction error adding run step doc: ', e))),
        from(updateDoc(doc(db, `Accounts/${accountId}/AgentRuns`, runDocId), {
        state: humanResponseState,
        lastModified: serverTimestamp(),
      })
      .then(() => console.log("Updated main run doc with human reaction " + runDocId))
      .catch((e) => console.error('sendHumanReaction error updating main run doc: ', e)))
      );
    };

    this.fetchAgentTemplate = (templateId) => {
      console.log('fetchAgentTemplate ' + templateId);
      return from(getDoc(doc(db, "Templates", templateId)))
      .pipe(map((snap) => snap.data().template))
    }

    this.streamAgentTemplates = () => {
      console.log('streamAgentTemplates ');
      return collectionData(query(collection(db, 'Templates'), orderBy('createdAt', 'desc')), { idField: 'docId' })
      .pipe(
        map(templates => templates.filter(template => !template.hide)),
        tap(templates => console.log("Got templates : " + templates.length)),
      );
    }

    this.streamAgentConnectors = (accPath) => {
      console.log('streamAgentConnectors ' + accPath);
      return collectionData(query(collection(db, accPath + '/Agents'), orderBy('createdAt', 'desc')), { idField: 'docId' })
        .pipe(
          map(agents => agents.filter(agent => !agent.deleted)),
          tap(agents => console.log("Got agent connectors : " + (agents.length ? JSON.stringify(agents): "none"))),
        );
    };
  
    this.updateAgentConnector = (accountId, agentDocId, agentName, agentWebhook, agentTriggerPayload, varPair1, varPair2, varPair3, deleteIt) => {
      console.log(`updateAgentConnector accountId: ${accountId} agentDocId: ${agentDocId} agentName: ${agentName} agentWebhook ${agentWebhook} agentTriggerPayload ${agentTriggerPayload} varPair1 ${varPair1} varPair2 ${varPair2} varPair3 ${varPair3}`);
      return from(updateDoc(doc(db, `Accounts/${accountId}/Agents`, agentDocId), {
        name: agentName,
        ...(agentWebhook && {webhook: agentWebhook}),
        ...(agentTriggerPayload && {triggerPayloadLabel1: agentTriggerPayload}),
        ...(varPair1 && {configKey1: varPair1[0]}),
        ...(varPair1 && {configVal1: varPair1[1]}),
        ...(varPair2 && {configKey2: varPair2[0]}),
        ...(varPair2 && {configVal2: varPair2[1]}),
        ...(varPair3 && {configKey3: varPair3[0]}),
        ...(varPair3 && {configVal3: varPair3[1]}),
        ...(deleteIt && {deleted: Timestamp.now()}),
      })
      .then(() => console.log("Updated agent connector " + agentDocId))
      .catch((e) => console.error('updateAgentConnector error updating doc: ', e)));
    };
  
    this.createAgentConnector = (accountId, agentId, agentName, agentWebhook, agentTriggerPayload, varPair1, varPair2, varPair3) => {
      console.log(`createAgentConnector accountId: ${accountId} agentId: ${agentId} agentName: ${agentName} agentWebhook ${agentWebhook} agentTriggerPayload ${agentTriggerPayload} varPair1 ${varPair1} varPair2 ${varPair2} varPair3 ${varPair3}`);
      const now = Timestamp.now();
      return from(addDoc(collection(db, `Accounts/${accountId}/Agents`), {
        createdAt: now,
        id: agentId,
        name: agentName,
        ...(agentWebhook && {webhook: agentWebhook}),
        ...(agentTriggerPayload && {triggerPayloadLabel1: agentTriggerPayload}),
        ...(varPair1 && {configKey1: varPair1[0]}),
        ...(varPair1 && {configVal1: varPair1[1]}),
        ...(varPair2 && {configKey2: varPair2[0]}),
        ...(varPair2 && {configVal2: varPair2[1]}),
        ...(varPair3 && {configKey3: varPair3[0]}),
        ...(varPair3 && {configVal3: varPair3[1]}),
      })
      .then((agentDoc) => console.log("Created agent connector " + agentDoc.id))
      .catch((e) => console.error('createAgentConnector error: ', e)));
    };

    this.triggerAgentManually = (accountId, agentId, actionValues) => {
      console.log(`triggerAgentManually ${accountId} ${agentId} ${actionValues}`);
      const callable = httpsCallable(functions, 'triggerManually');
      return callable({
        accountId: accountId,
        agentId: agentId,
        actionValues: actionValues
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.error(`triggerAgentManuallyERROR code ${code} message ${message} details ${details}`)
      })
      .then((result) => {console.log("triggerAgentManually response: " + JSON.stringify(result.data));return result.data})
    };
  }

  let instance;
  let db;
  let storage;
  let functions;

  return {
    getInstance: function() {
      if (instance == null) {
        instance = new SingletonClass();
        getAuth().useDeviceLanguage();
        db = getFirestore();
        storage = getStorage();
        functions = getFunctions(getApp(), 'europe-west1');
        // functions = firebase.app().functions('europe-west1');
        if (location.hostname === 'localhost') {
          // connectFunctionsEmulator(functions, "127.0.0.1", 5001);
          // connectFirestoreEmulator(db, '127.0.0.1', 8080);
          // OLD auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
        }

        instance.constructor = null;
      }
      return instance;
    },
    REVIEW_LOAD_LIMIT,
    LOCAL_STORAGE_FORM_KEY
  };
})();

export default Data;
